/*================================================================================
	Item Name: Admina - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.offcanvas {
  .offcanvas-header {
    .offcanvas-title {
      color: var(--bs-white);
    }
    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/0.75rem auto no-repeat;
    }
  }
}
.offcanvas.offcanvas-end {
  width: 500px;
}
.react-select.is-invalid {
  div {
    border-color: #ea5455 !important;
  }
}

.modal-header {
  background-color: var(--bs-primary) !important;
  .modal-title {
    color: var(--bs-white) !important;
  }
}